import { t } from "i18next";

export const USER_TABLE_TITLE_MAPPING = {
  ko: {
    name: "이름",
    studentid: "학번",
    grade: "학년",
    class: "분반",
    academicStatus: "학적상태",
    comment: "비고",
  },
  en: {
    name: "name",
    studentid: "studentid",
    grade: "grade",
    class: "class",
    academicStatus: "academicStatus",
    comment: "comment",
  },
};

export const USER_TABLE_TITLE_MAPPING_KR = {
  이름: "name",
  학번: "studentid",
  학년: "grade",
  분반: "class",
  학적상태: "academicStatus",
  비고: "comment",
};

export const EXAM_TABLE_TITLE_MAPPING = {
  name: "이름",
  studentid: "학번",
  exam_class: "시험 항목",
  totalScore: "합계 점수",
  exam_time: "시험 시간",
};

export const FILTER_OPTION = {
  grade: ["1", "2", "3", "4"],
  academicStatus: [t("Academic.enroll"), t("Academic.onLeave")],
  class: ["1", "2", "3"],
};

export const TITLE_MAPPING = [
  "Venipuncture",
  "Intramuscular",
  "Subcutaneous",
  "Intradermal",
  "Transfusion",
  "GavageFeeding",
  "NasalCannula",
  "Endotracheal",
  "StraightCatherization",
  "VitalSigns",
  "ECGmonitor",
  "UrinaryCatheterization",
  "CleansingEnema",
  "CPR",
  "JPDrain",
  "OralMedication",
  "SkinDefect",
  "PainManagement"
];

export const LANGUAGES_SWAP_MAPPING = {
  ko: {
    nativeName: "한국어",
    code: "ko",
    Emoji: "KR",
  },
  en: {
    nativeName: "English",
    code: "en",
    Emoji: "EN",
  },
};
