import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: ${(props) => (props.small ? "110px" : "300px")};
  height: 27px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #676d8380;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => props.disabled && "0.5"};
  pointer-events: ${(props) => props.disabled && "none"};
`;

export const Title = styled.label`
  color: #676d83;
  position: absolute;
  left: 0;
  bottom: 100%;
  cursor: default;
`;

export const IconP = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  svg {
    height: 8px;
  }
`;

export const SelectLabel = styled.label`
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 1;
  cursor: pointer;
  text-align: center;
  font-weight: 0;
`;

export const SelectOptions = styled.ul`
  width: 100%;
  position: absolute;
  display: ${({ visible }) => (visible ? "block" : "none")};
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  z-index: 2; /* Set z-index higher than label */
  text-align: center;
  top: 100%;
`;

export const SelectOption = styled.li`
  padding: 5px 10px;
  cursor: pointer;
  background-color: white;
  text-align: center;

  &:hover {
    background-color: #f2f2f2;
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: #007bff;
    color: #fff;
  `}
`;
